<template>
  <header ref="header">
    <section class="content">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <img class="img" src="/img/logo.svg" alt="patient genie" />
        </router-link>
      </div>

      <nav class="nav-container">
        <ul class="nav-list" data-testid="header-nav-list">
          <li class="nav-item">
            <v-btn text :to="{ name: 'Platform' }" class="nav-item-btn">Platform</v-btn>
          </li>
          <li class="nav-item">
            <v-menu attach=".nav-item" offset-y open-on-hover content-class="dropdown-menu">
              <template v-slot:activator="{ on, attrs, value }">
                <v-btn text v-bind="attrs" v-on="on" class="nav-item-btn">
                  <span>Solutions</span>

                  <v-icon>{{ value ? mdiChevronUp : mdiChevronDown }}</v-icon>
                </v-btn>
              </template>

              <div class="dropdown-menu-content">
                <ul class="dropdown-menu-list">
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'ProvidersBusiness' }"> Practices </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'HealthSystemsBusiness' }">
                      Health Systems
                    </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'EmployersAndPlansBusiness' }">
                      Employers & Plans
                    </router-link>
                  </li>
                </ul>
              </div>
            </v-menu>
          </li>
          <li class="nav-item">
            <v-menu attach=".nav-item" offset-y open-on-hover content-class="dropdown-menu">
              <template v-slot:activator="{ on, attrs, value }">
                <v-btn text v-bind="attrs" v-on="on" class="nav-item-btn company">
                  <span>Company</span>
                  <v-icon>{{ value ? mdiChevronUp : mdiChevronDown }}</v-icon>
                </v-btn>
              </template>

              <div class="dropdown-menu-content">
                <ul class="dropdown-menu-list">
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'About' }"> About us </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'About', hash: '#team' }"> Our team </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'Jobs' }"> Careers </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'NewsList' }"> News </router-link>
                  </li>
                  <li class="dropdown-menu-item">
                    <router-link :to="{ name: 'ContactUs' }">Contact</router-link>
                  </li>
                </ul>
              </div>
            </v-menu>
          </li>
        </ul>

        <button
          class="drawer-btn"
          data-testid="business-header-drawer-btn"
          @click="toggleIsDrawerOpened"
        >
          <img class="menu-icon" alt="menu icon" src="/icons/burger-menu.svg" />
        </button>
      </nav>
      <div class="action-links-wrapper">
        <AppButton
          height="40"
          class="action-link action-link_demo"
          data-testid="business-header-demo-link"
          @click="handleTryNowClick"
        >
          Try now
        </AppButton>
      </div>
    </section>
  </header>
</template>

<script lang="ts">
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import throttle from "@/services/throttle";
import { AppButton } from "pg-ui";
import openNewTab from "@/services/openNewTab";
import { mdiChevronDown, mdiChevronUp } from "@mdi/js";

export default Vue.extend({
  name: "BusinessHeader",
  components: {
    AppButton,
  },
  data() {
    return {
      mdiChevronUp,
      mdiChevronDown,
      adminProfileUrl: process.env.VUE_APP_PROFILE_URL,
      headerEl: null as null | HTMLElement,
      scrollThrottle: null as null | any,
      showWebsiteToggle: true,
    };
  },
  computed: {
    ...mapGetters({
      getIsDrawerOpened: "getIsDrawerOpened",
    }),
  },
  mounted() {
    this.$nextTick(() => {
      this.headerEl = this.$refs.header as HTMLElement;
      this.scrollThrottle = throttle(this.scrollPageHandler, 100);
      window.addEventListener("scroll", this.scrollThrottle);
    });
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollThrottle);
  },
  methods: {
    ...mapActions(["setIsDrawerOpened"]),
    toggleIsDrawerOpened() {
      this.setIsDrawerOpened(!this.getIsDrawerOpened);
    },
    scrollPageHandler() {
      const currentScrollPosition = window.scrollY;

      if (!this.headerEl) return;

      const headerHeight = this.headerEl.offsetHeight;

      this.showWebsiteToggle = currentScrollPosition < headerHeight;
    },
    handleTryNowClick() {
      openNewTab(process.env.VUE_APP_ASK_WEBSITE_URL!);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

header {
  position: sticky;
  top: 0;
  z-index: 5;
  width: 100%;
  background-color: var(--color-white);
  box-shadow: 0 2px 18px 0 rgb(0 0 0 / 0.08);
  transition: background-color 0.3s ease;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1920px;
  height: var(--header-with-search-height);
  margin: 0 auto;
  padding: 0 44px;

  @include breakpoint(small) {
    padding: 0 20px;
  }
}

.logo {
  max-height: 34px;
  margin-right: 24px;

  @include breakpoint(small) {
    & a {
      display: flex;
    }
  }

  .img {
    height: 34px;
  }
}

.nav-container {
  margin-left: auto;

  .nav-list {
    display: flex;

    @include breakpoint(small) {
      display: none;
    }
  }
}

.nav-item-btn {
  font-weight: var(--font-weight-bolder);
  font-size: var(--font-medium);
  letter-spacing: normal;
  text-transform: none;
  transition: color 0.2s ease-in-out;

  &::v-deep .v-btn__content {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  &.v-btn {
    padding: 0 12px;
  }

  &.v-btn:focus {
    color: var(--color-purple-primary);

    &::before {
      opacity: 0;
    }
  }

  &.v-btn--active {
    color: var(--color-purple-primary);

    &::before {
      opacity: 0;
    }
  }

  &:hover {
    color: var(--color-purple-primary);
  }
}

.v-menu__content::v-deep.dropdown-menu {
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 10px 25px 0 rgb(7 18 46 / 0.1);

  .dropdown-menu-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: 0;
    padding: 0;
  }

  .dropdown-menu-item {
    padding: 6px 8px;

    a {
      color: var(--color-grey-primary);
      font-weight: var(--font-weight-medium);
      transition: color 0.2s ease-in-out;

      &.router-link-exact-active {
        color: var(--color-purple-primary);
      }

      &:hover {
        color: var(--color-purple-primary);
      }
    }
  }
}

.drawer-btn {
  display: none;
  text-align: right;

  @media (max-width: 1024px) {
    display: flex;
    width: auto;
    margin-left: 24px;
  }

  .menu-icon {
    height: 20px;
    cursor: pointer;
  }
}

.action-links-wrapper {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-left: 16px;

  @include breakpoint-reverse(small) {
    margin-left: 24px;
  }

  .action-link {
    height: 40px;
    border-radius: 8px;

    &_login {
      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_demo {
      color: var(--color-white);

      &::v-deep {
        .button-text {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }
    }

    &_login,
    &_demo {
      display: flex;

      @include breakpoint(small) {
        display: none;
      }
    }
  }
}

.drawer-controls-wrapper {
  .user-header-menu {
    display: none;

    @include breakpoint-reverse(small) {
      display: block;
    }

    &::v-deep .user-avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }

  .user-drawer-menu {
    display: flex;

    @include breakpoint-reverse(small) {
      display: none;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
}
</style>
