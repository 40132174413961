<template>
  <footer
    :class="[
      { 'footer-default': isDefaultFooter },
      { conversation: isConversationPage },
      { 'white-bg': isWhiteBg },
    ]"
  >
    <template v-if="isDefaultFooter">
      <div class="content">
        <div class="logo-wrapper">
          <img src="/img/logo-white.svg" width="46" alt="" />

          <a
            href="https://www.linkedin.com/company/patientgenie/"
            target="_blank"
            aria-label="linkedin link"
            class="linkedin-link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M22.2234 0H1.77187C0.792187 0 0 0.773438 0 1.72969V22.2656C0 23.2219 0.792187 24 1.77187 24H22.2234C23.2031 24 24 23.2219 24 22.2703V1.72969C24 0.773438 23.2031 0 22.2234 0ZM7.12031 20.4516H3.55781V8.99531H7.12031V20.4516ZM5.33906 7.43438C4.19531 7.43438 3.27188 6.51094 3.27188 5.37187C3.27188 4.23281 4.19531 3.30937 5.33906 3.30937C6.47813 3.30937 7.40156 4.23281 7.40156 5.37187C7.40156 6.50625 6.47813 7.43438 5.33906 7.43438ZM20.4516 20.4516H16.8937V14.8828C16.8937 13.5562 16.8703 11.8453 15.0422 11.8453C13.1906 11.8453 12.9094 13.2937 12.9094 14.7891V20.4516H9.35625V8.99531H12.7687V10.5609H12.8156C13.2891 9.66094 14.4516 8.70938 16.1813 8.70938C19.7859 8.70938 20.4516 11.0813 20.4516 14.1656V20.4516Z"
                fill="white"
              />
            </svg>
          </a>
        </div>
        <nav class="links">
          <ul>
            <li>
              <router-link :to="{ path: '/privacy' }">Privacy policy</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/terms' }">Terms of service</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/notices' }">Notices</router-link>
            </li>
            <li>
              <router-link :to="{ path: '/contact-us' }">Contact</router-link>
            </li>
          </ul>
        </nav>
        <div class="about">
          <p class="about-description">
            <span class="about-description-title">PatientGenie</span> is the AI health agent that
            assists users in navigating the complexities of healthcare. Powered by Generative AI and
            a patented data engine, and integrated with a wide range of data and technology
            partners, PatientGenie serves as a personalized, all-in-one healthcare companion,
            guiding users through choosing and scheduling the right care options tailored to their
            needs.
          </p>
        </div>
      </div>
      <div class="copy">© PatientGenie, Inc. {{ currentYear }}</div>
    </template>
    <template v-else> © PatientGenie, Inc. {{ currentYear }}</template>
  </footer>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "TheFooter",
  props: {
    isDefaultFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    isConversationPage(): boolean {
      return this.$route.name === "Conversation";
    },
    isWhiteBg(): boolean {
      return (
        this.$route.meta?.isAuthRoute ||
        this.$route.name === "PatientProfile" ||
        this.$route.name === "ProviderProfile"
      );
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins";

footer {
  bottom: 0;
  width: 100%;
  padding: 44px 0 24px;
  color: var(--color-grey-primary);
  text-align: center;
  background: transparent;

  &.white-bg {
    background-color: var(--color-white);

    @include breakpoint-reverse(xsmall) {
      background-color: transparent;
    }
  }

  &.footer-default {
    text-align: left;
    background-color: var(--color-purple-primary);

    .content {
      display: grid;
      grid-template-areas: "logo links about";
      grid-template-columns: 50px 1fr 0.7fr;
      gap: 100px;
      max-width: 1920px;
      margin: 0 auto;
      padding: 0 44px;
      color: var(--color-white);

      @include breakpoint(mdlarge) {
        grid-template-columns: auto auto auto;
        gap: 50px;
      }

      @include breakpoint(large) {
        gap: 44px;
      }

      @include breakpoint(medium) {
        padding: 0 50px;
      }

      @include breakpoint(small) {
        grid-template-areas:
          "logo logo logo"
          "links links about";
        grid-template-columns: 1fr 1fr 1fr;
      }

      @include breakpoint(xsmall) {
        grid-template-areas:
          "logo"
          "links"
          "about";
        grid-template-columns: 1fr;
        gap: 24px;
        padding: 0 20px;
      }
    }

    .logo-wrapper {
      grid-area: logo;

      @include breakpoint(xsmall) {
        margin: 0 auto;
      }
    }

    .linkedin-link {
      display: flex;
      margin-top: 44px;
      margin-bottom: 24px;

      @include breakpoint(xsmall) {
        justify-content: center;
      }
    }

    .links {
      display: grid;
      grid-area: links;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 44px;

      @include breakpoint(xsmall) {
        grid-template-columns: 1fr;
        gap: 16px;
        text-align: center;
      }

      ul {
        display: flex;
        flex-direction: column;
        grid-column: 2;
        gap: 16px;
        margin: 0;
        padding: 0;
        font-size: var(--font-medium);

        @include breakpoint(xsmall) {
          grid-column: 1;
        }

        &:last-child {
          @include breakpoint(xsmall) {
            margin-top: -16px;
          }
        }

        a {
          color: var(--color-white);
          font-weight: var(--font-weight-medium);
        }
      }
    }

    .empty-title {
      @include breakpoint(xsmall) {
        display: none;
      }
    }

    .links-title {
      font-weight: var(--font-weight-bolder);
      font-size: var(--font-extra-small);
      text-transform: uppercase;
    }

    .about {
      grid-area: about;

      .about-description {
        max-width: 520px;
        margin-left: auto;
        color: var(--color-white);

        &-title {
          font-weight: var(--font-weight-bolder);
        }

        @include breakpoint(small) {
          max-width: unset;
        }
      }
    }

    .copy {
      margin-top: 44px;
      color: var(--color-white);
      text-align: center;

      @include breakpoint(xsmall) {
        margin-top: 14px;
      }
    }
  }

  &.conversation {
    @include breakpoint(xsmall) {
      display: none;
    }
  }
}
</style>
